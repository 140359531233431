<template>
  <c-box
    width="100%"
    mb="1rem"
  >
    <c-flex
      margin-bottom="16px"
      gap="16px"
      width="100%"
      align-items="center"
    >
      <c-box width="100%">
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          white-space="nowrap"
        >
          Daftar Hadiah
        </BaseText>
      </c-box>

      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left="15px"
            height="24px"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            height="62px"
            placeholder="Pencarian"
            border-radius="20px"
            padding-left="50px"
            position="relative"
            z-index="1"
            @keyup="onSearch"
          />
        </c-form-control>
      </c-box>

      <c-box>
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          @click="$router.push({ name:'admin.referrals.gift' })"
        >
          Tambah Hadiah
        </BaseButton>
      </c-box>
    </c-flex>

    <c-box
      v-if="items.length > 0"
      width="100%"
    >
      <BaseTable2
        :columns="columns"
        :items="items"
        :page="$route?.query?.['list-reward-page'] ? parseInt($route?.query?.['list-reward-page']) : 1"
        :per-page="$route?.query?.['list-reward-perpage'] ? parseInt($route?.query?.['list-reward-perpage']) : 5"
        :total-page="calculatePerPage(total, $route?.query?.['list-reward-perpage'] ? parseInt($route?.query?.['list-reward-perpage']) : 5)"
        @on-change-page="(page_) => changePage(page_)"
        @on-change-per-page="(perPage_) => changePerPage(perPage_)"
      >
        <template #xxx2="{ item }">
          <!--          <pre>-->
          <!--            <code>-->
          <!--              {{ JSON.stringify(item,null,2) }}-->
          <!--            </code>-->
          <!--          </pre>-->
          <c-popover
            v-slot="{ onClose }"
            placement="bottom"
          >
            <c-popover-trigger>
              <BaseButton
                font-size="14px"
                border-radius="12px"
                max-height="36px"
                variant="outlined"
                :right-svg-icon="require('@/assets/icons/icon-arrow-down-2.svg')"
                right-svg-icon-color="#008C81"
                right-svg-icon-no-margin
              >
                Pilih Aksi
              </BaseButton>
            </c-popover-trigger>
            <c-popover-content
              z-index="4"
              width="150px"
            >
              <c-button
                background-color="white"
                width="100%"
                color="primary.400"
                @click="() => {
                  $router.push({
                    name: 'admin.referrals.edit',
                    params: {
                      id: item.id
                    }
                  })
                  onClose()
                }"
              >
                Edit
              </c-button>
              <c-button
                v-if="item?.isActive === 0"
                background-color="white"
                width="100%"
                color="primary.400"
                @click="() => {
                  isOpenModalActiveGift = item.id
                  onClose()
                }"
              >
                Aktifkan
              </c-button>
              <c-button
                v-if="item?.isActive === 1"
                background-color="white"
                width="100%"
                color="primary.400"
                @click="() => {
                  isOpenModalNonactiveGift = item.id
                  onClose()
                }"
              >
                Nonaktifkan
              </c-button>
              <c-button
                background-color="white"
                width="100%"
                color="danger.400"
                @click="() => {
                  isOpenModalDeleteGift = item.id
                  onClose()
                }"
              >
                Hapus
              </c-button>
            </c-popover-content>
          </c-popover>
        </template>
      </BaseTable2>
    </c-box>
    <c-flex
      v-else
      w="100%"
      flex-direction="column"
      justify-content="center"
      align-items="center"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
        width="300px"
        mt="3rem"
        mb="1rem"
      />
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        text-align="center"
      >
        Saat ini data belum tersedia
      </c-text>
    </c-flex>

    <!-- MODAL NONAKTIFKAN HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalNonactiveGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin non aktifkan hadiah?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalNonactiveGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingNonactiveGift"
              @click="onNonactiveGift"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL AKTIFKAN HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalActiveGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin aktifkan hadiah?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalActiveGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingActiveGift"
              @click="onActiveGift"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL HAPUS HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalDeleteGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin menghapus hadiah?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalDeleteGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingDeleteGift"
              @click="onDeleteGift"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import { CBox, CImage, CText, CFormControl, CInput, CFlex } from '@chakra-ui/vue'
import { reqGetListReferralGifts } from '@/requests/dietela-api/other'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import { parseErrorCatch } from '@/utils/parse-error-catch'

export default {
  name: 'ListClaimReward',
  components: {
    BaseModal,
    BaseButton,
    BaseText,
    BaseTable2,
    CBox,
    CImage,
    CText,
    CFlex,
    CFormControl,
    CInput,
  },
  data() {
    return {
      isOpenModalNonactiveGift: false,
      isOpenModalActiveGift: false,
      isOpenModalDeleteGift: false,

      isSubmittingNonactiveGift: false,
      isSubmittingActiveGift: false,
      isSubmittingDeleteGift: false,

      total: 0,
      items: [],
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '30px',
        },
        {
          id: 'name',
          label: 'NAMA HADIAH',
          type: 'TEXT',
          width: '200px',
        },
        {
          id: 'giftsCategory.categoryName',
          label: 'JENIS',
          type: 'TEXT',
          width: '100px',
          customRender: (item) => {
            return item?.giftsCategory?.categoryName || '-'
          },
        },
        {
          id: 'price',
          label: 'HARGA',
          type: 'TEXT',
          width: '70px',
          customRender: (item) => {
            return item?.price ? `${item?.price} poin` : '-'
          },
        },
        {
          id: 'isActive',
          label: 'STATUS HADIAH',
          type: 'TEXT',
          width: '110px',
          align: 'center',
          itemAlign: 'center',
          customRender: (item) => {
            return item?.isActive === 1 ? 'Aktif' : 'Tidak Aktif'
          },
        },
        {
          id: 'xxx2',
          label: 'AKSI',
          type: 'CUSTOM2',
          itemAlign: 'center',
          align: 'center',
          width: '80px',
        },
      ],
      search: this.$route.query?.['list-reward-q'] ?? '',
      timer: null,
    }
  },
  computed: {
    queryTableListReferral() {
      // for trigger watch (fetch data)
      return `${this.$route?.query?.['list-reward-page']} ${this.$route?.query?.['list-reward-perpage']} ${this.$route?.query?.['list-reward-q']}`
    },
  },
  watch: {
    queryTableListReferral() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqGetListReferralGifts(this.$store.getters.axios, {
        page: this.$route?.query?.['list-reward-page'] || 1,
        perpage: this.$route?.query?.['list-reward-perpage'] || 5,
        q: this.$route?.query?.['list-reward-q'] || '',
      })
      this.items = res?.data?.data?.rows || []
      this.total = res?.data?.data?.count || 0
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    async onNonactiveGift() {
      try {
        this.isSubmittingNonactiveGift = true
        await reqReferral.editGift(
          this.$store.getters.axios,
          {
            type: 'CHANGE_STATUS',
            giftId: this.isOpenModalNonactiveGift,
            isActive: 0,
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-reward-page'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-reward-page']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalNonactiveGift = false
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingNonactiveGift = false
      }
    },
    async onActiveGift() {
      try {
        this.isSubmittingActiveGift = true
        await reqReferral.editGift(
          this.$store.getters.axios,
          {
            type: 'CHANGE_STATUS',
            giftId: this.isOpenModalActiveGift,
            isActive: 1,
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-reward-page'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-reward-page']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalActiveGift = false
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingActiveGift = false
      }
    },
    async onDeleteGift() {
      try {
        this.isSubmittingDeleteGift = true
        await reqReferral.deleteGift(
          this.$store.getters.axios,
          {
            giftId: this.isOpenModalDeleteGift,
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-reward-page'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-reward-page']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalDeleteGift = false
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingDeleteGift = false
      }
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route?.query || {},
            ['list-reward-q']: this.search || undefined,
            ['list-reward-page']: 1,
          },
          params: { savePosition: true },
        }).catch()
      }, 1000)
    },
    changePage(page) {
      if (this.page !== page) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-reward-page']: page,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
    changePerPage(perPage) {
      if (this.perPage !== perPage) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-reward-page']: 1,
            ['list-reward-perpage']: perPage,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
  },
}
</script>
