<template>
  <c-box
    width="100%"
    mb="1rem"
  >
    <c-flex
      margin-bottom="16px"
      gap="16px"
      width="100%"
      align-items="center"
    >
      <c-box width="100%">
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          white-space="nowrap"
        >
          Daftar Referral
        </BaseText>
      </c-box>

      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left="15px"
            height="24px"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            height="62px"
            placeholder="Pencarian"
            border-radius="20px"
            padding-left="50px"
            position="relative"
            z-index="1"
            @keyup="onSearch"
          />
        </c-form-control>
      </c-box>
    </c-flex>

    <c-box
      v-if="items.length > 0"
      width="100%"
    >
      <BaseTable2
        :columns="columns"
        :items="items"
        :page="$route?.query?.page ? parseInt($route?.query?.page) : 1"
        :per-page="$route?.query?.perpage ? parseInt($route?.query?.perpage) : 5"
        :total-page="calculatePerPage(total, $route?.query?.perpage ? parseInt($route?.query?.perpage) : 5)"
        :sort="sort"
        @on-change-page="(page_) => changePage(page_)"
        @on-change-per-page="(perPage_) => changePerPage(perPage_)"
        @on-change-sort="(sort_) => onChangeSort(sort_)"
      />
    </c-box>
    <c-flex
      v-else
      w="100%"
      flex-direction="column"
      justify-content="center"
      align-items="center"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
        width="300px"
        mt="3rem"
        mb="1rem"
      />
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        text-align="center"
      >
        Saat ini data belum tersedia
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import { CBox, CImage, CText, CFormControl, CInput, CFlex } from '@chakra-ui/vue'
import { reqGetListReferral } from '@/requests/dietela-api/other'

export default {
  name: 'ListGift',
  components: {
    BaseText,
    BaseTable2,
    CBox,
    CImage,
    CText,
    CFlex,
    CFormControl,
    CInput,
  },
  data() {
    return {
      total: 0,
      items: [],
      sort: {},
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '30px',
        },
        {
          id: 'title',
          label: 'NAMA USER',
          type: 'TEXT',
          width: '200px',
          customRender: (item) => {
            return item?.firstName && item?.lastName ? `${item?.firstName} ${item?.lastName}`?.trim() : '-'
          },
        },
        {
          id: 'code',
          label: 'KODE REFERRAL',
          type: 'TEXT',
        },
        {
          id: 'email',
          label: 'ALAMAT EMAIL',
          type: 'TEXT',
          width: '200px',
        },
        {
          id: 'xxx0',
          label: 'TOTAL REFERRAL',
          type: 'TEXT',
          sortable: true,
          customRender: (item) => {
            return item?.referrals?.clientIdsUse?.length || 0
          },
        },
        {
          id: 'xxx1',
          label: 'TOTAL POIN',
          type: 'TEXT',
          sortable: true,
          customRender: (item) => {
            return item?.referralsPoin?.finalPoin || 0
          },
        },
        {
          id: 'xxx2',
          label: 'AKSI',
          type: 'BUTTON',
          itemAlign: 'end',
          width: '80px',
          customRender: (item) => {
            return {
              buttonText: 'Lihat',
              onButton: {
                click: () => {
                  this.$router.push({
                    name: 'admin.referrals.detail',
                    params: {
                      id: item?.id,
                    }, 
                  })
                },
              },
              bindButton: {
                disabled: false,
                variant: 'outlined',
              },
            }
          },
        },
      ],
      search: this.$route.query.q ?? '',
      timer: null,
    }
  },
  computed: {
    queryTableListReferral() {
      // for trigger watch (fetch data)
      return `${this.$route?.query?.page} ${this.$route?.query?.perpage} ${this.$route?.query?.q} ${this.$route?.query?.orderBy} ${this.$route?.query?.order}`
    },
  },
  watch: {
    queryTableListReferral() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqGetListReferral(this.$store.getters.axios, {
        page: this.$route?.query?.page || 1,
        perpage: this.$route?.query?.perpage || 5,
        q: this.$route?.query?.q || '',
        orderBy: this.$route?.query?.orderBy || '',
        order: this.$route?.query?.order || '',
      })
      this.items = res?.data?.data || []
      this.total = res?.data?.meta?.total || 0
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route?.query || {},
            q: this.search || undefined,
            page: 1,
          },
          params: { savePosition: true },
        }).catch()
      }, 1000)
    },
    changePage(page) {
      if (this.page !== page) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: page,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
    changePerPage(perPage) {
      if (this.perPage !== perPage) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            perpage: perPage,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
    onChangeSort(sort) {
      this.sort = sort
      const order = Object.values(sort)[0]?.toLowerCase()
      let field = Object.keys(sort)[0]
      switch (field) {
        case 'xxx0':
          field = 'total_referral'
          break
        case 'xxx1':
          field = 'total_poin'
          break
        default:
          break
      }

      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          orderBy: field,
          order: order,
        },
        params: { savePosition: true },
      }).catch()
    },
  },
}
</script>
