var render = function () {
  var _vm$$route, _vm$$route$query, _vm$$route2, _vm$$route2$query, _vm$$route3, _vm$$route3$query, _vm$$route4, _vm$$route4$query, _vm$$route5, _vm$$route5$query, _vm$$route6, _vm$$route6$query;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "mb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Daftar Penukaran Hadiah ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _vm.items.length > 0 ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseTable2', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.items,
      "page": (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$query = _vm$$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query['list-claim-reward-page'] ? parseInt((_vm$$route2 = _vm.$route) === null || _vm$$route2 === void 0 ? void 0 : (_vm$$route2$query = _vm$$route2.query) === null || _vm$$route2$query === void 0 ? void 0 : _vm$$route2$query['list-claim-reward-page']) : 1,
      "per-page": (_vm$$route3 = _vm.$route) !== null && _vm$$route3 !== void 0 && (_vm$$route3$query = _vm$$route3.query) !== null && _vm$$route3$query !== void 0 && _vm$$route3$query['list-claim-reward-perpage'] ? parseInt((_vm$$route4 = _vm.$route) === null || _vm$$route4 === void 0 ? void 0 : (_vm$$route4$query = _vm$$route4.query) === null || _vm$$route4$query === void 0 ? void 0 : _vm$$route4$query['list-claim-reward-perpage']) : 5,
      "total-page": _vm.calculatePerPage(_vm.total, (_vm$$route5 = _vm.$route) !== null && _vm$$route5 !== void 0 && (_vm$$route5$query = _vm$$route5.query) !== null && _vm$$route5$query !== void 0 && _vm$$route5$query['list-claim-reward-perpage'] ? parseInt((_vm$$route6 = _vm.$route) === null || _vm$$route6 === void 0 ? void 0 : (_vm$$route6$query = _vm$$route6.query) === null || _vm$$route6$query === void 0 ? void 0 : _vm$$route6$query['list-claim-reward-perpage']) : 5)
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        return _vm.changePerPage(perPage_);
      }
    },
    scopedSlots: _vm._u([{
      key: "caseStatus",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('BaseChip', {
          attrs: {
            "label": item === null || item === void 0 ? void 0 : item.caseStatus,
            "color": (item === null || item === void 0 ? void 0 : item.status) === 'success' ? 'primary' : (item === null || item === void 0 ? void 0 : item.status) === 'failed' ? 'danger' : 'warning',
            "screen": "mobile-only"
          }
        })];
      }
    }], null, false, 3509761835)
  })], 1) : _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Saat ini data belum tersedia ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalDetailGift,
      "close-on-overlay-click": false,
      "with-button-close": true,
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'lg' : 'xl'
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalDetailGift = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "margin-top": "24px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "text-align": "center"
          }
        }, [_vm._v(" Detail Penukaran Hadiah ")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$selectedDetail;

        return [_c('c-box', {
          attrs: {
            "padding": "30px 40px 0 40px"
          }
        }, [_c('c-flex', {
          attrs: {
            "justify-content": "space-between",
            "align-items": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px-2"
          }
        }, [_vm._v(" Reedem ID " + _vm._s(_vm.selectedDetail.idShort) + " ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px-2"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDateV2(_vm.selectedDetail.createdAt, 6)) + " ")])], 1), _c('c-grid', {
          attrs: {
            "margin-top": "30px",
            "padding": "0 8px",
            "template-columns": "repeat(2, 1fr)"
          }
        }, [_c('c-box', [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px"
          }
        }, [_vm._v(" Nama User ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.name) + " ")])], 1), _c('c-box', [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px"
          }
        }, [_vm._v(" Alamat Email ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.email) + " ")])], 1)], 1), _c('BaseDivider'), _c('c-grid', {
          attrs: {
            "margin-top": "16px",
            "padding": "0 8px",
            "template-columns": "repeat(2, 1fr)"
          }
        }, [_c('c-box', [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px"
          }
        }, [_vm._v(" Hadiah Ditukar ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.gift) + " ")])], 1), !!((_vm$selectedDetail = _vm.selectedDetail) !== null && _vm$selectedDetail !== void 0 && _vm$selectedDetail.quantity) ? _c('c-box', [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px"
          }
        }, [_vm._v(" Kuantitas ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.quantity) + " ")])], 1) : _vm._e()], 1), _c('BaseDivider'), _c('c-box', {
          attrs: {
            "margin-top": "24px",
            "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
            "border-radius": "8px",
            "padding": "16px 16px 2px 16px"
          }
        }, [_vm.selectedDetail.type === 'PROGRAM_GRATIS' ? [_c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Jenis Program ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.programName) + " ")]), _c('BaseDivider')] : _vm.selectedDetail.type === 'BARANG_GRATIS' ? [_c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Jenis Barang ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.gift) + " ")]), _c('BaseDivider')] : _vm.selectedDetail.type === 'EWALLET' ? [_c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Nama Penerima e-Wallet/Rekening ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.ewalletOwner) + " ")]), _c('BaseDivider'), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Nama Merchant/Bank ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.ewalletMerchant) + " ")]), _c('BaseDivider'), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Nomor e-Wallet/Rekening ")]), _c('c-flex', {
          attrs: {
            "align-items": "center",
            "justify-content": "space-between"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.ewalletNumber) + " ")]), _c('input', {
          ref: "no_rek",
          attrs: {
            "type": "hidden",
            "value": "082331337354627"
          }
        }), _c('c-flex', {
          attrs: {
            "cursor": "pointer",
            "gap": "6px",
            "font-size": ['12px', '16px'],
            "font-weight": "500",
            "color": "primary.400"
          },
          on: {
            "click": function click($event) {
              return _vm.copyClipboard('no_rek');
            }
          }
        }, [_vm._v(" Salin "), _c('c-image', {
          attrs: {
            "src": require('@/assets/icons/icon-copy.svg'),
            "width": ['16px', '24px'],
            "height": ['16px', '24px']
          }
        })], 1)], 1), _c('BaseDivider')] : _vm._e()], 2), _vm.selectedDetail.status === 'inprogress' ? _c('c-box', {
          attrs: {
            "margin-top": "20px"
          }
        }, [_c('BaseInputSelect', {
          attrs: {
            "label": "Status Penukaran",
            "options": _vm.optionStatusGift,
            "placeholder": "Pilih Aksi",
            "full-width": ""
          },
          model: {
            value: _vm.statusGift,
            callback: function callback($$v) {
              _vm.statusGift = $$v;
            },
            expression: "statusGift"
          }
        })], 1) : _vm._e(), ['success', 'failed'].includes(_vm.selectedDetail.status) ? _c('c-flex', {
          attrs: {
            "margin": "20px 0",
            "justify-content": "space-between",
            "align-items": "center",
            "background-color": _vm.selectedDetail.status === 'success' ? 'primary.50' : 'danger.50',
            "padding": "16px"
          }
        }, [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "align-items": "center",
            "width": "100%",
            "border-right": "2px solid",
            "border-color": _vm.selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": _vm.selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'
          }
        }, [_vm._v(" Status Penukaran ")])], 1), _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "align-items": "center",
            "width": "100%"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": _vm.selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedDetail.status === 'success' ? 'SUDAH DIKIRIM' : _vm.selectedDetail.status === 'failed' ? 'DIBATALKAN' : '-') + " ")])], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "60px",
            "padding-right": "60px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_vm.selectedDetail.status === 'inprogress' ? _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalDetailGift = false;
            }
          }
        }, [_vm._v(" Batal ")]) : _vm._e(), _vm.selectedDetail.status === 'inprogress' ? _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingDetailGift,
            "disabled": !_vm.statusGift
          },
          on: {
            "click": _vm.onDetailGift
          }
        }, [_vm._v(" Konfirmasi ")]) : _vm._e(), _vm.selectedDetail.status !== 'inprogress' ? _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalDetailGift = false;
            }
          }
        }, [_vm._v(" Oke ")]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalDeclineGift,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'lg' : 'xl'
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "margin-top": "24px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "text-align": "center"
          }
        }, [_vm._v(" Pilih alasan pembatalan hadiah "), _c('br'), _vm._v(" Alasan akan ditampilkan pada notifikasi user ")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "padding": "30px 40px 0 40px"
          }
        }, [_c('c-box', [_c('BaseInputSelect', {
          attrs: {
            "label": "Alasan Pembatalan Hadiah",
            "options": _vm.optionRedeemCancelReason,
            "placeholder": "Pilih alasan pembatalan hadiah",
            "full-width": ""
          },
          model: {
            value: _vm.reasonDeclineGift,
            callback: function callback($$v) {
              _vm.reasonDeclineGift = $$v;
            },
            expression: "reasonDeclineGift"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "60px",
            "padding-right": "60px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalDeclineGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingDeclineGift,
            "disabled": !_vm.reasonDeclineGift
          },
          on: {
            "click": _vm.onDeclineGift
          }
        }, [_vm._v(" Konfirmasi ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalConfirmationAlreadyPaidGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin hadiah sudah dibayarkan? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationAlreadyPaidGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingConfirmationAlreadyPaidGift
          },
          on: {
            "click": _vm.onConfirmAcceptGift
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalConfirmationDeclineGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin hadiah dibatalkan? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationDeclineGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingConfirmationDeclineGift
          },
          on: {
            "click": _vm.onConfirmDeclineGift
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }