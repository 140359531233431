var render = function () {
  var _vm$$route, _vm$$route$query, _vm$$route2, _vm$$route2$query, _vm$$route3, _vm$$route3$query, _vm$$route4, _vm$$route4$query, _vm$$route5, _vm$$route5$query, _vm$$route6, _vm$$route6$query;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "mb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Daftar Referral ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _vm.items.length > 0 ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseTable2', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.items,
      "page": (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$query = _vm$$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.page ? parseInt((_vm$$route2 = _vm.$route) === null || _vm$$route2 === void 0 ? void 0 : (_vm$$route2$query = _vm$$route2.query) === null || _vm$$route2$query === void 0 ? void 0 : _vm$$route2$query.page) : 1,
      "per-page": (_vm$$route3 = _vm.$route) !== null && _vm$$route3 !== void 0 && (_vm$$route3$query = _vm$$route3.query) !== null && _vm$$route3$query !== void 0 && _vm$$route3$query.perpage ? parseInt((_vm$$route4 = _vm.$route) === null || _vm$$route4 === void 0 ? void 0 : (_vm$$route4$query = _vm$$route4.query) === null || _vm$$route4$query === void 0 ? void 0 : _vm$$route4$query.perpage) : 5,
      "total-page": _vm.calculatePerPage(_vm.total, (_vm$$route5 = _vm.$route) !== null && _vm$$route5 !== void 0 && (_vm$$route5$query = _vm$$route5.query) !== null && _vm$$route5$query !== void 0 && _vm$$route5$query.perpage ? parseInt((_vm$$route6 = _vm.$route) === null || _vm$$route6 === void 0 ? void 0 : (_vm$$route6$query = _vm$$route6.query) === null || _vm$$route6$query === void 0 ? void 0 : _vm$$route6$query.perpage) : 5),
      "sort": _vm.sort
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        return _vm.changePerPage(perPage_);
      },
      "on-change-sort": function onChangeSort(sort_) {
        return _vm.onChangeSort(sort_);
      }
    }
  })], 1) : _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Saat ini data belum tersedia ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }