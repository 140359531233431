<template>
  <c-box
    width="100%"
    mb="1rem"
  >
    <c-flex
      margin-bottom="16px"
      gap="16px"
      width="100%"
      align-items="center"
    >
      <c-box width="100%">
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          white-space="nowrap"
        >
          Daftar Penukaran Hadiah
        </BaseText>
      </c-box>

      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            left="15px"
            height="24px"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            height="62px"
            placeholder="Pencarian"
            border-radius="20px"
            padding-left="50px"
            position="relative"
            z-index="1"
            @keyup="onSearch"
          />
        </c-form-control>
      </c-box>
    </c-flex>

    <c-box
      v-if="items.length > 0"
      width="100%"
    >
      <BaseTable2
        :columns="columns"
        :items="items"
        :page="$route?.query?.['list-claim-reward-page'] ? parseInt($route?.query?.['list-claim-reward-page']) : 1"
        :per-page="$route?.query?.['list-claim-reward-perpage'] ? parseInt($route?.query?.['list-claim-reward-perpage']) : 5"
        :total-page="calculatePerPage(total, $route?.query?.['list-claim-reward-perpage'] ? parseInt($route?.query?.['list-claim-reward-perpage']) : 5)"
        @on-change-page="(page_) => changePage(page_)"
        @on-change-per-page="(perPage_) => changePerPage(perPage_)"
      >
        <template #caseStatus="{ item }">
          <BaseChip
            :label="item?.caseStatus"
            :color="item?.status === 'success' ? 'primary' : item?.status === 'failed' ? 'danger' : 'warning'"
            screen="mobile-only"
          />
        </template>
      </BaseTable2>
    </c-box>
    <c-flex
      v-else
      w="100%"
      flex-direction="column"
      justify-content="center"
      align-items="center"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
        width="300px"
        mt="3rem"
        mb="1rem"
      />
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        text-align="center"
      >
        Saat ini data belum tersedia
      </c-text>
    </c-flex>

    <!-- MODAL DETAIL PENUKARAN HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalDetailGift"
      :close-on-overlay-click="false"
      :with-button-close="true"
      :size="['sm', 'md'].includes(currentBreakpoint) ? 'lg' : 'xl'"
      @close="isOpenModalDetailGift = false"
    >
      <template #header>
        <c-box margin-top="24px">
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            text-align="center"
          >
            Detail Penukaran Hadiah
          </BaseText>
        </c-box>
      </template>
      <template #body>
        <c-box
          padding="30px 40px 0 40px"
        >
          <c-flex
            justify-content="space-between"
            align-items="center"
          >
            <BaseText
              size-mobile="12px"
              size-desktop="14px-2"
            >
              Reedem ID {{ selectedDetail.idShort }}
            </BaseText>

            <BaseText
              size-mobile="12px"
              size-desktop="14px-2"
            >
              {{ formatDateV2(selectedDetail.createdAt, 6) }}
            </BaseText>
          </c-flex>

          <c-grid
            margin-top="30px"
            padding="0 8px"
            template-columns="repeat(2, 1fr)"
          >
            <c-box>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                Nama User
              </BaseText>
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                {{ selectedDetail.name }}
              </BaseText>
            </c-box>

            <c-box>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                Alamat Email
              </BaseText>
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                {{ selectedDetail.email }}
              </BaseText>
            </c-box>
          </c-grid>

          <BaseDivider />

          <c-grid
            margin-top="16px"
            padding="0 8px"
            template-columns="repeat(2, 1fr)"
          >
            <c-box>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                Hadiah Ditukar
              </BaseText>
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                {{ selectedDetail.gift }}
              </BaseText>
            </c-box>

            <c-box v-if="!!selectedDetail?.quantity">
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                Kuantitas
              </BaseText>
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                {{ selectedDetail.quantity }}
              </BaseText>
            </c-box>
          </c-grid>

          <BaseDivider />

          <c-box
            margin-top="24px"
            box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
            border-radius="8px"
            padding="16px 16px 2px 16px"
          >
            <template v-if="selectedDetail.type === 'PROGRAM_GRATIS'">
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                Jenis Program
              </BaseText>
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
                color="primary.400"
              >
                {{ selectedDetail.programName }}
              </BaseText>

              <BaseDivider />
            </template>

            <template v-else-if="selectedDetail.type === 'BARANG_GRATIS'">
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                Jenis Barang
              </BaseText>
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
                color="primary.400"
              >
                {{ selectedDetail.gift }}
              </BaseText>

              <BaseDivider />
            </template>

            <template v-else-if="selectedDetail.type === 'EWALLET'">
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                Nama Penerima e-Wallet/Rekening
              </BaseText>
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
                color="primary.400"
              >
                {{ selectedDetail.ewalletOwner }}
              </BaseText>

              <BaseDivider />

              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                Nama Merchant/Bank
              </BaseText>
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
                color="primary.400"
              >
                {{ selectedDetail.ewalletMerchant }}
              </BaseText>

              <BaseDivider />

              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="neutral.darkGray"
              >
                Nomor e-Wallet/Rekening
              </BaseText>
              <c-flex
                align-items="center"
                justify-content="space-between"
              >
                <BaseText

                  size-mobile="18px"
                  size-desktop="20px"
                  color="primary.400"
                >
                  {{ selectedDetail.ewalletNumber }}
                </BaseText>
                <input
                  ref="no_rek"
                  type="hidden"
                  value="082331337354627"
                >
                <c-flex
                  cursor="pointer"
                  gap="6px"
                  :font-size="['12px', '16px']"
                  font-weight="500"
                  color="primary.400"
                  @click="copyClipboard('no_rek')"
                >
                  Salin
                  <c-image
                    :src="require('@/assets/icons/icon-copy.svg')"
                    :width="['16px', '24px']"
                    :height="['16px', '24px']"
                  />
                </c-flex>
              </c-flex>

              <BaseDivider />
            </template>
          </c-box>

          <c-box
            v-if="selectedDetail.status === 'inprogress'"
            margin-top="20px"
          >
            <BaseInputSelect
              v-model="statusGift"
              label="Status Penukaran"
              :options="optionStatusGift"
              placeholder="Pilih Aksi"
              full-width
            />
          </c-box>

          <c-flex
            v-if="['success', 'failed'].includes(selectedDetail.status)"
            margin="20px 0"
            justify-content="space-between"
            align-items="center"
            :background-color="selectedDetail.status === 'success' ? 'primary.50' : 'danger.50'"
            padding="16px"
          >
            <c-flex
              justify-content="center"
              align-items="center"
              width="100%"
              border-right="2px solid"
              :border-color="selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'"
            >
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
                :color="selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'"
              >
                Status Penukaran
              </BaseText>
            </c-flex>

            <c-flex
              justify-content="center"
              align-items="center"
              width="100%"
            >
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
                :color="selectedDetail.status === 'success' ? 'primary.400' : 'danger.400'"
              >
                {{ selectedDetail.status === 'success'
                  ? 'SUDAH DIKIRIM'
                  : selectedDetail.status === 'failed'
                    ? 'DIBATALKAN'
                    : '-' }}
              </BaseText>
            </c-flex>
          </c-flex>
        </c-box>
      </template>
      <template
        #footer
      >
        <c-box
          width="100%"
          padding-left="60px"
          padding-right="60px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              v-if="selectedDetail.status === 'inprogress'"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalDetailGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              v-if="selectedDetail.status === 'inprogress'"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingDetailGift"
              :disabled="!statusGift"
              @click="onDetailGift"
            >
              Konfirmasi
            </BaseButton>
            <BaseButton
              v-if="selectedDetail.status !== 'inprogress'"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="isOpenModalDetailGift = false"
            >
              Oke
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL BATALKAN HADIAH-->
    <BaseModal
      :is-open="!!isOpenModalDeclineGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :size="['sm', 'md'].includes(currentBreakpoint) ? 'lg' : 'xl'"
    >
      <template #header>
        <c-box margin-top="24px">
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            text-align="center"
          >
            Pilih alasan pembatalan hadiah <br>
            Alasan akan ditampilkan pada notifikasi user
          </BaseText>
        </c-box>
      </template>
      <template #body>
        <c-box
          padding="30px 40px 0 40px"
        >
          <c-box>
            <BaseInputSelect
              v-model="reasonDeclineGift"
              label="Alasan Pembatalan Hadiah"
              :options="optionRedeemCancelReason"
              placeholder="Pilih alasan pembatalan hadiah"
              full-width
            />
          </c-box>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="60px"
          padding-right="60px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalDeclineGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingDeclineGift"
              :disabled="!reasonDeclineGift "
              @click="onDeclineGift"
            >
              Konfirmasi
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL KONFIRMASI HADIAH SUDAH DIBAYAR-->
    <BaseModal
      :is-open="!!isOpenModalConfirmationAlreadyPaidGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin hadiah sudah dibayarkan?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalConfirmationAlreadyPaidGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingConfirmationAlreadyPaidGift"
              @click="onConfirmAcceptGift"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL KONFIRMASI HADIAH DIBATALKAN-->
    <BaseModal
      :is-open="!!isOpenModalConfirmationDeclineGift"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin hadiah dibatalkan?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalConfirmationDeclineGift = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingConfirmationDeclineGift"
              @click="onConfirmDeclineGift"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import { CBox, CImage, CText, CFormControl, CInput, CFlex } from '@chakra-ui/vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import { formatDateV2 } from '@/utils/format-date'
import BaseChip from '@/components/elements/base-chip.vue'
// import BaseInputText from '@/components/elements/base-input-text.vue'

export default {
  name: 'ListClaimGift',
  components: {
    BaseChip,
    // BaseInputText,
    BaseInputSelect,
    BaseDivider,
    BaseModal, BaseButton,
    BaseText,
    BaseTable2,
    CBox,
    CImage,
    CText,
    CFlex,
    CFormControl,
    CInput,
  },
  mixins: [mixinsCheckBreakpoints()],
  data() {
    return {
      optionRedeemCancelReason: [],
      optionStatusGift: [],

      isOpenModalDetailGift: false,
      isOpenModalDeclineGift: false,
      isOpenModalConfirmationAlreadyPaidGift: false,
      isOpenModalConfirmationDeclineGift: false,

      isSubmittingDetailGift: false,
      isSubmittingDeclineGift: false,
      isSubmittingConfirmationAlreadyPaidGift: false,
      isSubmittingConfirmationDeclineGift: false,

      isFetchingDetailGift: false,

      selectedDetail: {
        type: '', // PROGRAM_GRATIS | EWALLET | BARANG_GRATIS
        id: '',
        idShort: '',
        name: '',
        email: '',
        gift: '',
        status: '', // success | failed | inprogress
        createdAt: '',
        quantity: null,
        // PROGRAM_GRATIS PROPERTIES
        programName: '',
        // EWALLET PROPERTIES
        ewalletOwner: '',
        ewalletMerchant: '',
        ewalletNumber: '',
      },

      statusGift: '',
      reasonDeclineGift: '',
      // reasonDeclineGiftOther: '',
      total: 0,
      items: [],
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '30px',
        },
        {
          id: 'idShort',
          label: 'ID',
          type: 'TEXT',
          align: 'center',
          itemAlign: 'center',
        },
        {
          id: 'createdAt',
          label: 'TANGGAL',
          type: 'TEXT',
          align: 'center',
          itemAlign: 'center',
          width: '100px',
          customRender: (item) => {
            return formatDateV2(item?.createdAt, 6)
          },
        },
        {
          id: 'client.firstName',
          label: 'NAMA USER',
          type: 'TEXT',
          width: '200px',
          customRender: (item) => {
            return item?.client?.firstName && item?.client?.lastName ? `${item?.client?.firstName} ${item?.client?.lastName}`?.trim() : '-'
          },
        },
        {
          id: 'client.email',
          label: 'ALAMAT EMAIL',
          type: 'TEXT',
          width: '200px',
          customRender: (item) => item?.client?.email || '-',
        },
        {
          id: 'referralsGifts.name',
          label: 'HADIAH PENUKARAN',
          type: 'TEXT',
          width: '150px',
          customRender: (item) => item?.referralsGifts?.name || '-',
        },
        {
          id: 'caseStatus',
          label: 'STATUS PENUKARAN',
          type: 'CUSTOM2',
          width: '140px',
        },
        {
          id: 'xxx2',
          label: 'AKSI',
          type: 'BUTTON',
          itemAlign: 'end',
          width: '80px',
          customRender: (item) => {
            return {
              buttonText: 'Lihat',
              onButton: {
                click: async() => {
                  try {
                    this.isFetchingDetailGift = true
                    this.statusGift = ''
                    this.reasonDeclineGift = ''
                    // this.reasonDeclineGiftOther = ''
                    const res = await reqReferral.detailRedeem(
                      this.$store.getters.axios, {
                        redeemId: item?.id,
                      })
                    const data = res?.data?.data
                    this.optionStatusGift = data?.redeemStatusOptions || []
                    this.optionRedeemCancelReason = data?.redeemCancelReason?.map((v) =>
                      ({
                        value: v,
                        label: v,
                      }),
                    ) || []

                    let giftType = 'BARANG_GRATIS'
                    if (data?.productServiceId) {
                      giftType = 'PROGRAM_GRATIS'
                    } else if (data?.accountName) {
                      giftType = 'EWALLET'
                    }
                    this.selectedDetail = {
                      type: giftType,
                      id: data?.id,
                      idShort: data?.idShort,
                      name: data?.referralName,
                      email: data?.referralEmail,
                      gift: data?.giftName,
                      status: data?.status,
                      quantity: data?.quantity,
                      // PROGRAM_GRATIS PROPERTIES
                      programName: `${data?.productName} ${data?.programsService}`.trim(),
                      // EWALLET PROPERTIES
                      ewalletOwner: data?.accountName,
                      ewalletMerchant: data?.merchantName,
                      ewalletNumber: data?.accountNumber,
                      createdAt: data?.createdAt,
                    }
                    this.isOpenModalDetailGift = true
                  } catch (e) {
                    this.$toast ({
                      status: 'error',
                      title: 'Error',
                      description: parseErrorCatch(e),
                      duration: 3000,
                    })
                  } finally {
                    this.isFetchingDetailGift = false
                  }
                },
              },
              bindButton: {
                disabled: this.isFetchingDetailGift,
                variant: 'outlined',
              },
            }
          },
        },
      ],
      search: this.$route.query?.['list-claim-reward-q'] ?? '',
      timer: null,
    }
  },
  computed: {
    queryTableListReferral() {
      // for trigger watch (fetch data)
      return `${this.$route?.query?.['list-claim-reward-page']} ${this.$route?.query?.['list-claim-reward-perpage']} ${this.$route?.query?.['list-claim-reward-q']}`
    },
  },
  watch: {
    queryTableListReferral() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    async init() {
      const res = await reqReferral.redeem(this.$store.getters.axios, {
        page: this.$route?.query?.['list-claim-reward-page'] || 1,
        perpage: this.$route?.query?.['list-claim-reward-perpage'] || 5,
        q: this.$route?.query?.['list-claim-reward-q'] || '',
      })
      this.items = res?.data?.data?.rows || []
      this.total = res?.data?.data?.count || 0
    },
    async onDetailGift() {
      if (this.statusGift === 'failed') {
        this.isOpenModalDetailGift = false
        this.isOpenModalDeclineGift = true
      } else if (this.statusGift === 'success') {
        this.isOpenModalDetailGift = false
        this.isOpenModalConfirmationAlreadyPaidGift = true
      }
    },
    async onDeclineGift() {
      this.isOpenModalDeclineGift = false
      this.isOpenModalConfirmationDeclineGift = true
    },
    async onConfirmAcceptGift() {
      try {
        this.isSubmittingConfirmationAlreadyPaidGift = true
        await reqReferral.editRedeem(this.$store.getters.axios, {
          redeemId: this.selectedDetail.id,
          status: this.statusGift,
        })
        this.isOpenModalConfirmationAlreadyPaidGift = false
        this.init()
      } catch (e) {
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingConfirmationAlreadyPaidGift = false
      }
    },
    async onConfirmDeclineGift() {
      try {
        this.isSubmittingConfirmationDeclineGift = true
        await reqReferral.editRedeem(this.$store.getters.axios, {
          redeemId: this.selectedDetail.id,
          status: this.statusGift,
          note: this.reasonDeclineGift, // this.reasonDeclineGift === 'LAINNYA' ? this.reasonDeclineGiftOther : this.reasonDeclineGift,
        })
        this.isOpenModalConfirmationDeclineGift = false
        this.init()
      } catch (e) {
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingConfirmationDeclineGift = false
      }
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    copyClipboard(ref) {
      const el = this.$refs[ref]
      try {
        el.setAttribute('type', 'text')
        el.select()
        document.execCommand('copy')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil disalin',
          duration: 2000,
        })
        this.isShowSteps = false
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: 'Oops, unable to copy',
          duration: 2000,
        })
      }
      el.setAttribute('type', 'hidden')
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route?.query || {},
            ['list-claim-reward-q']: this.search || undefined,
            ['list-claim-reward-page']: 1,
          },
          params: { savePosition: true },
        }).catch()
      }, 1000)
    },
    changePage(page) {
      if (this.page !== page) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-claim-reward-page']: page,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
    changePerPage(perPage) {
      if (this.perPage !== perPage) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-claim-reward-page']: 1,
            ['list-claim-reward-perpage']: perPage,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
  },
}
</script>
