var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "min-width": "0"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Referral',
        href: '/admin/referrals',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Manajemen Kode AjakSehat ")]), _c('c-box', {
    attrs: {
      "margin-top": "32px",
      "width": "100%"
    }
  }, [_c('ListReferralScheme')], 1), _c('c-box', {
    attrs: {
      "margin-top": "32px",
      "width": "100%"
    }
  }, [_c('ListReferral')], 1), _c('c-box', {
    attrs: {
      "margin-top": "32px",
      "width": "100%"
    }
  }, [_c('ListClaimGift')], 1), _c('c-box', {
    attrs: {
      "margin-top": "32px",
      "width": "100%"
    }
  }, [_c('ListGift')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }