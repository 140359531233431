<template>
  <c-box
    width="100%"
    mb="1rem"
  >
    <c-flex
      margin-bottom="16px"
      gap="16px"
      width="100%"
      align-items="center"
    >
      <c-flex
        width="100%"
        justify-content="space-between"
        align-items="center"  
      >
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          white-space="nowrap"
        >
          Pengaturan Skema Kode AjakSehat
        </BaseText>
        
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          @click="$router.push({ name:'admin.referrals.schema' })"
        >
          Tambah Skema
        </BaseButton>
      </c-flex>
    </c-flex>

    <c-box
      v-if="items.length > 0"
      width="100%"
    >
      <BaseTable2
        :columns="columns"
        :items="items"
        :page="$route?.query?.['list-scheme'] ? parseInt($route?.query?.['list-scheme']) : 1"
        :per-page="$route?.query?.['list-scheme-perpage'] ? parseInt($route?.query?.['list-scheme-perpage']) : 5"
        :total-page="calculatePerPage(total, $route?.query?.['list-scheme-perpage'] ? parseInt($route?.query?.['list-scheme-perpage']) : 5)"
        @on-change-page="(page_) => changePage(page_)"
        @on-change-per-page="(perPage_) => changePerPage(perPage_)"
      >
        <template #xxx2="{ item }">
          <c-popover
            v-slot="{ onClose }"
            placement="bottom"
          >
            <c-popover-trigger>
              <BaseButton
                font-size="14px"
                border-radius="12px"
                max-height="36px"
                variant="outlined"
                gap="4px"
              >
                Pilih Aksi
                <inline-svg
                  :src="require('@/assets/icons/icon-arrow-down-2.svg')"
                  width="20px"
                  fill="#008C81"
                />
              </BaseButton>
            </c-popover-trigger>
            <c-popover-content
              z-index="4"
              width="150px"
            >
              <c-button
                background-color="white"
                width="100%"
                color="primary.400"
                @click="() => {
                  $router.push({
                    name: 'admin.referrals.schema.detail',
                    params: {
                      id: item?.id
                    }
                  })
                  onClose()
                }"
              >
                Lihat
              </c-button>
              <c-button
                background-color="white"
                width="100%"
                color="primary.400"
                @click="() => {
                  if (item?.status === 0) {
                    isOpenModalActiveSkema = item.id
                  } else {
                    isOpenModalNonactiveSkema = item.id
                  }
                  onClose()
                }"
              >
                {{ item?.status === 0 ? 'Aktifkan' : 'Nonaktifkan' }}
              </c-button>
              <c-button
                background-color="white"
                width="100%"
                color="danger.400"
                @click="() => {
                  isOpenModalDeleteSkema = item.id
                  onClose()
                }"
              >
                Hapus
              </c-button>
            </c-popover-content>
          </c-popover>
        </template>
      </BaseTable2>
    </c-box>
    <c-flex
      v-else
      w="100%"
      flex-direction="column"
      justify-content="center"
      align-items="center"
    >
      <c-image
        src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
        width="300px"
        mt="3rem"
        mb="1rem"
      />
      <c-text
        :font-size="['16px', '18px']"
        font-weight="500"
        text-align="center"
      >
        Saat ini data belum tersedia
      </c-text>
    </c-flex>

    <!-- MODAL NONAKTIFKAN SKEMA-->
    <BaseModal
      :is-open="!!isOpenModalNonactiveSkema"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          pt="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px-2"
            size-desktop="18px-2"
            color="primary.400"
            margin-bottom="8px"
          >
            Apakah kamu yakin ingin menonaktifkan skema?
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="primary.400"
          >
            Skema akan dihentikan dan akan berdampak pada pendapatan poin di sisi user akan berdampak pada pendapatan poin di sisi user
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalNonactiveSkema = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingNonactiveSkema"
              @click="onNonactiveSkema"
            >
              Konfirmasi
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL AKTIFKAN SKEMA-->
    <BaseModal
      :is-open="!!isOpenModalActiveSkema"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          pt="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px-2"
            size-desktop="18px-2"
            color="primary.400"
            margin-bottom="8px"
          >
            Apakah kamu yakin ingin mengaktifkan skema?
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="primary.400"
          >
            Skema ini akan dimulai sesuai dengan tanggal periode skema
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalActiveSkema = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingActiveSkema"
              @click="onActiveSkema"
            >
              Konfirmasi
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL DELETE SKEMA-->
    <BaseModal
      :is-open="!!isOpenModalDeleteSkema"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          pt="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px-2"
            size-desktop="18px-2"
            color="primary.400"
            margin-bottom="8px"
          >
            Apakah kamu yakin ingin menghapus skema?
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="primary.400"
          >
            Skema yang dihapus tidak dapat dikembalikan, jika dihapus saat sedang aktif maka akan berdampakpada pendapatan poin di sisi user
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalDeleteSkema = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmittingDeleteSkema"
              @click="onDeleteSkema"
            >
              Konfirmasi
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { CBox, CImage, CText, CFlex } from '@chakra-ui/vue'
import BaseButton from '@/components/elements/base-button.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import { formatCurrency } from '@/utils/format-currency'
import { parseErrorCatch } from '@/utils/parse-error-catch'

export default {
  name: 'ListReferralScheme',
  components: {
    BaseButton,
    BaseText,
    BaseTable2,
    BaseModal,
    CBox,
    CImage,
    CText,
    CFlex,
  },
  data() {
    return {
      isOpenModalNonactiveSkema: false,
      isOpenModalActiveSkema: false,
      isOpenModalDeleteSkema: false,

      isSubmittingNonactiveSkema: false,
      isSubmittingActiveSkema: false,
      isSubmittingDeleteSkema: false,

      total: 0,
      items: [],
      columns: [
        {
          id: 'no',
          label: 'NO',
          type: 'AUTO_INCREMENT',
          align: 'center',
          itemAlign: 'center',
          width: '20px',
        },
        {
          id: 'startAt',
          label: 'PERIODE SKEMA',
          type: 'TEXT',
          width: '70px',
          customRender: (item) => {
            return `${item?.startAt} - ${item?.endAt}`
          },
        },
        {
          id: 'discount',
          label: 'POTONGAN KODE',
          type: 'TEXT',
          width: '70px',
          customRender: (item) => {
            return item?.discountType == 'percent' ? `${item?.discount}%` : `${formatCurrency(item.discount, 3)}`
          },
        },
        {
          id: 'products',
          label: 'PROGRAM TERPILIH',
          type: 'TEXT',
          customRender: (item) => {
            return item?.products.join(', ')
          },
          width: '150px',
        },
        {
          id: 'poin',
          label: 'HADIAH POIN',
          type: 'TEXT',
          customRender: (item) => {
            return `${formatCurrency(item?.poinMultiplier, 4)} Poin / ${formatCurrency(item?.poinDivider, 3)}`
          },
          width: '80px',
        },
        {
          id: 'status',
          label: 'STATUS SKEMA',
          type: 'TEXT',
          customRender: (item) => {
            return item?.status === 1 ? 'Aktif' : (item?.status == 0 ? 'Tidak Aktif' : 'Pending')
          },
          width: '70px',
        },
        {
          id: 'xxx2',
          label: 'AKSI',
          type: 'CUSTOM2',
          width: '80px',
        },
      ],
      timer: null,
    }
  },
  computed: {
    queryTableListReferral() {
      // for trigger watch (fetch data)
      return `${this.$route?.query?.['list-scheme']} ${this.$route?.query?.['list-scheme-perpage']}`
    },
  },
  watch: {
    queryTableListReferral() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await reqReferral.schema(this.$store.getters.axios, {
        page: this.$route?.query?.['list-scheme'] || 1,
        perpage: this.$route?.query?.['list-scheme-perpage'] || 5,
      })
      this.items = res?.data?.data || []
      this.total = res?.data?.meta?.total || 0
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    async onNonactiveSkema() {
      try {
        this.isSubmittingNonactiveSkema = true
        await reqReferral.updateSchemaStatus(
          this.$store.getters.axios,
          {
            schemaId: this.isOpenModalNonactiveSkema,
            status: 'non-active',
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-scheme'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-scheme']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalNonactiveSkema = false
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Schema successfully deactivated',
          position: 'bottom-right',
          duration: 3000,
        })
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingNonactiveSkema = false
      }
    },
    async onActiveSkema() {
      try {
        this.isSubmittingActiveSkema = true
        await reqReferral.updateSchemaStatus(
          this.$store.getters.axios,
          {
            schemaId: this.isOpenModalActiveSkema,
            status: 'active',
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-scheme'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-scheme']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalActiveSkema = false
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Schema successfully activated',
          position: 'bottom-right',
          duration: 3000,
        })
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingActiveSkema = false
      }
    },
    async onDeleteSkema() {
      try {
        this.isSubmittingDeleteSkema = true
        await reqReferral.deleteSchema(
          this.$store.getters.axios,
          {
            schemaId: this.isOpenModalDeleteSkema,
          },
        )
        // refetch table after deleted
        if ([1, '1'].includes(this.$route.query ['list-scheme'])) {
          await this.init()
        } else {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ['list-scheme']: 1,
            },
            params: { savePosition: true },
          }).catch()
        }
        this.isOpenModalDeleteSkema = false
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Schema successfully deleted',
          position: 'bottom-right',
          duration: 3000,
        })
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSubmittingDeleteSkema = false
      }
    },
    changePage(page) {
      if (this.page !== page) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-scheme']: page,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
    changePerPage(perPage) {
      if (this.perPage !== perPage) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ['list-scheme']: 1,
            ['list-scheme-perpage']: perPage,
          },
          params: { savePosition: true },
        }).catch()
      }
    },
  },
}
</script>