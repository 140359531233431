<template>
  <c-box
    flex-grow="1"
    min-width="0"
  >
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Referral',
          href: '/admin/referrals',
          isCurrent: true
        },
      ]"
    />
    <c-box
      width="100%"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <BaseText
        size-mobile="20px"
        size-desktop="28px"
      >
        Manajemen Kode AjakSehat
      </BaseText>

      <!-- DAFTAR SKEMA REFERRAL -->
      <c-box
        margin-top="32px"
        width="100%"
      >
        <ListReferralScheme />
      </c-box>

      <!-- DAFTAR REFERRAL -->
      <c-box
        margin-top="32px"
        width="100%"
      >
        <ListReferral />
      </c-box>

      <!-- DAFTAR PENUKARAN HADIAH -->
      <c-box
        margin-top="32px"
        width="100%"
      >
        <ListClaimGift />
      </c-box>

      <!-- DAFTAR HADIAH -->
      <c-box
        margin-top="32px"
        width="100%"
      >
        <ListGift />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import ListReferral from '@/views/admin/referrals/_views/list-referral.vue'
import BaseText from '@/components/elements/base-text.vue'
import { CBox } from '@chakra-ui/vue'
import ListClaimGift from '@/views/admin/referrals/_views/list-claim-gift.vue'
import ListGift from '@/views/admin/referrals/_views/list-gift.vue'
import ListReferralScheme from '@/views/admin/referrals/_views/list-referral-scheme.vue'

export default {
  metaInfo: {
    title: 'Manajemen Kode AjakSehat | Dietela',
  },
  name: 'ManagementCoachingChallengePage',
  components: {
    ListGift,
    ListClaimGift,
    BaseText,
    ListReferral,
    BreadcrumbPath,
    CBox,
    ListReferralScheme,
  },
  mixins: [generalMixin],
}
</script>
