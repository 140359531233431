var render = function () {
  var _vm$$route, _vm$$route$query, _vm$$route2, _vm$$route2$query, _vm$$route3, _vm$$route3$query, _vm$$route4, _vm$$route4$query, _vm$$route5, _vm$$route5$query, _vm$$route6, _vm$$route6$query;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "mb": "1rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Daftar Hadiah ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-box', [_c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.referrals.gift'
        });
      }
    }
  }, [_vm._v(" Tambah Hadiah ")])], 1)], 1), _vm.items.length > 0 ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseTable2', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.items,
      "page": (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$query = _vm$$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query['list-reward-page'] ? parseInt((_vm$$route2 = _vm.$route) === null || _vm$$route2 === void 0 ? void 0 : (_vm$$route2$query = _vm$$route2.query) === null || _vm$$route2$query === void 0 ? void 0 : _vm$$route2$query['list-reward-page']) : 1,
      "per-page": (_vm$$route3 = _vm.$route) !== null && _vm$$route3 !== void 0 && (_vm$$route3$query = _vm$$route3.query) !== null && _vm$$route3$query !== void 0 && _vm$$route3$query['list-reward-perpage'] ? parseInt((_vm$$route4 = _vm.$route) === null || _vm$$route4 === void 0 ? void 0 : (_vm$$route4$query = _vm$$route4.query) === null || _vm$$route4$query === void 0 ? void 0 : _vm$$route4$query['list-reward-perpage']) : 5,
      "total-page": _vm.calculatePerPage(_vm.total, (_vm$$route5 = _vm.$route) !== null && _vm$$route5 !== void 0 && (_vm$$route5$query = _vm$$route5.query) !== null && _vm$$route5$query !== void 0 && _vm$$route5$query['list-reward-perpage'] ? parseInt((_vm$$route6 = _vm.$route) === null || _vm$$route6 === void 0 ? void 0 : (_vm$$route6$query = _vm$$route6.query) === null || _vm$$route6$query === void 0 ? void 0 : _vm$$route6$query['list-reward-perpage']) : 5)
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        return _vm.changePerPage(perPage_);
      }
    },
    scopedSlots: _vm._u([{
      key: "xxx2",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('c-popover', {
          attrs: {
            "placement": "bottom"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var onClose = _ref2.onClose;
              return [_c('c-popover-trigger', [_c('BaseButton', {
                attrs: {
                  "font-size": "14px",
                  "border-radius": "12px",
                  "max-height": "36px",
                  "variant": "outlined",
                  "right-svg-icon": require('@/assets/icons/icon-arrow-down-2.svg'),
                  "right-svg-icon-color": "#008C81",
                  "right-svg-icon-no-margin": ""
                }
              }, [_vm._v(" Pilih Aksi ")])], 1), _c('c-popover-content', {
                attrs: {
                  "z-index": "4",
                  "width": "150px"
                }
              }, [_c('c-button', {
                attrs: {
                  "background-color": "white",
                  "width": "100%",
                  "color": "primary.400"
                },
                on: {
                  "click": function click() {
                    _vm.$router.push({
                      name: 'admin.referrals.edit',
                      params: {
                        id: item.id
                      }
                    });

                    onClose();
                  }
                }
              }, [_vm._v(" Edit ")]), (item === null || item === void 0 ? void 0 : item.isActive) === 0 ? _c('c-button', {
                attrs: {
                  "background-color": "white",
                  "width": "100%",
                  "color": "primary.400"
                },
                on: {
                  "click": function click() {
                    _vm.isOpenModalActiveGift = item.id;
                    onClose();
                  }
                }
              }, [_vm._v(" Aktifkan ")]) : _vm._e(), (item === null || item === void 0 ? void 0 : item.isActive) === 1 ? _c('c-button', {
                attrs: {
                  "background-color": "white",
                  "width": "100%",
                  "color": "primary.400"
                },
                on: {
                  "click": function click() {
                    _vm.isOpenModalNonactiveGift = item.id;
                    onClose();
                  }
                }
              }, [_vm._v(" Nonaktifkan ")]) : _vm._e(), _c('c-button', {
                attrs: {
                  "background-color": "white",
                  "width": "100%",
                  "color": "danger.400"
                },
                on: {
                  "click": function click() {
                    _vm.isOpenModalDeleteGift = item.id;
                    onClose();
                  }
                }
              }, [_vm._v(" Hapus ")])], 1)];
            }
          }], null, true)
        })];
      }
    }], null, false, 2889757356)
  })], 1) : _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Saat ini data belum tersedia ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalNonactiveGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin non aktifkan hadiah? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalNonactiveGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingNonactiveGift
          },
          on: {
            "click": _vm.onNonactiveGift
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalActiveGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin aktifkan hadiah? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalActiveGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingActiveGift
          },
          on: {
            "click": _vm.onActiveGift
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalDeleteGift,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin menghapus hadiah? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalDeleteGift = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmittingDeleteGift
          },
          on: {
            "click": _vm.onDeleteGift
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }